import React from "react"
import Page from "../components/Page"
import useStoryblok, { getCropedImage } from "../utils/storyblok"
import Seo from "../components/Seo"

export default function PageIndex({ pageContext, location }) {
  const story = useStoryblok(pageContext.story, location)
  const locale = pageContext.language
  const gatsbyOgImage = getCropedImage(story.content.meta.og_image, 1600, 900)

  return (
    <>
      <Seo
        title={story.content.meta.title}
        description={story.content.meta.description}
        image={gatsbyOgImage}
        locale={locale}
        pathname={story.full_slug}
      />
      <Page blok={story.content} locale={locale} />
    </>
  )
}
